import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Bounce, ToastContainer } from 'react-toastify';
import { FaTwitter, FaDiscord, FaReadme, FaTiktok, FaInstagram, FaTelegram } from "react-icons/fa";
import WalletChecker from './WalletChecker';


export default function App() {
  return (
    <>
      <div className="flex justify-center p-4 sm:px-8 xl:px-24">
        <div className="flex w-full max-w-[1200px] items-center justify-between">
          <div className="flex items-center space-x-1 text-white">
            <img alt="The Cat Protocol" loading="lazy" width="57" height="57" className="hidden sm:block" src="/logo.png" />
            <img alt="The Cat Protocol" loading="lazy" width="40" height="40" className="block sm:hidden" src="/logo.png" />
            <div className="hidden text-white text-base font-bold xs:block">The Cat Protocol</div>
          </div>

          <a href='https://thecatprotocol.finance/' target='_blank' className="h-fit rounded-lg bg-white px-4 py-2 text-base text-black sm:px-7 sm:py-4 sm:text-lg">
            Launch App
          </a>
        </div>
      </div>


      <div className="flex justify-center p-4 pt-12 sm:px-8 md:pt-24 xl:px-24">
        <div className="flex w-full max-w-[1200px] flex-col items-center justify-between gap-[76px] sm:gap-[100px] md:gap-[144px] lg:gap-[192px]">
          <div className="flex w-full flex-col gap-10 lg:gap-20">
            <div className="relative flex h-[25vh] w-full justify-center sm:h-[40vh] lg:hidden">
              <img alt="The Cat Protocol" className="z-10 filter-none" src='/logo.png' style={{
                height: "100 %",
                width: "100 %",
                left: "0",
                top: "0",
                right: "0",
                bottom: "0",
                objectFit: "contain",
                color: "transparent",
              }} />
            </div>

            <div className="z-10 flex flex-col gap-[28px] lg:max-w-[750px]">
              <h1 className="text-center text-[40px] font-extrabold leading-[29px] text-white sm:text-[50px] sm:leading-[40px] lg:text-left lg:text-[64px] lg:leading-[47px]">The Cat Protocol</h1>
              <div className="w-full text-center text-3xl font-semibold text-white sm:text-4xl lg:max-w-[750px] lg:text-left lg:text-6xl">Leverage the power of staking on Solana</div>
            </div>

            <div className="flex justify-between gap-4">
              <div className="flex flex-1 grow-[2] flex-col items-center gap-5">
                <div className="w-full max-w-[800px] lg:min-w-96">
                  <div className="relative h-0 pb-[87.37864077669903%] flex flex-col gap-2">
                    <h2 className='text-white text-3xl text-center font-semibold'>Protocol Socials</h2>
                    <div className='flex flex-row gap-4 justify-center'>
                      <a className='bg-white rounded-md p-2' href="https://x.com/thecatprotocol" target='_blank' rel="noreferrer">
                        <FaTwitter size='30' />
                      </a>
                      <a className='bg-white rounded-md p-2' href="https://discord.gg/ewE7s6CFgE" target='_blank' rel="noreferrer">
                        <FaDiscord size='30' />
                      </a>
                      <a className='bg-white rounded-md p-2' href="https://thecatprotocol.gitbook.io/protocol/" target='_blank' rel="noreferrer">
                        <FaReadme size='30' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden h-[150%] w-full flex-1 grow-[2.5] -translate-y-1/3 lg:block">
                <div className="size-full rounded-[857px] blur-[150px]" style={{ background: "linear-gradient(87deg, #d5d5d4 -36.79%, #000000 114.4%)" }}></div>
                <img alt="The Cat Protocol" className="z-10 filter-none" src='/logo.png' style={{
                  position: "absolute",
                  height: "100 %",
                  width: "100 %",
                  left: "0",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  objectFit: "contain",
                  color: "transparent",
                }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WalletChecker />

      <ToastContainer autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="light"
        transition={Bounce}
      />
    </>
  )
}