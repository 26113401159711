import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { toast } from 'react-toastify';
import WALLETS from './wallets';

const WalletChecker = () => {
  const [walletAddress, setWalletAddress] = useState('')

  const checkEligibility = () => {
    if (!walletAddress) { return }
    if (WALLETS.includes(walletAddress)) {
      toast.success('You wallet is eligible for airdrop', {
        position: "bottom-center",
      })
    } else {
      toast.error('You wallet is not eligible for airdrop', {
        position: "bottom-center",
      })
    }
  }

  return (
    <div className="flex justify-center p-4 sm:px-8 xl:px-24">
      <div className="flex flex-col gap-2 w-full max-w-[1200px]">
        <h3 className="text-white text-3xl font-semibold w-full">Airdrop eligibility</h3>

        <div class="flex align-items-center w-full bg-[#F7F8F9] h-auto mb-[30px] mx-auto px-6 py-4 border rounded-[30px] border-[#e6e6e6]">
          <input
            onSubmit={checkEligibility}
            onChange={event => setWalletAddress(event.target?.value)}
            type="text"
            class="w-full bg-transparent outline-0 px-2"
            placeholder="Enter you wallet address"
          />
          <button onClick={checkEligibility} class="flex items-center gap-4">
            <span><FaSearch /></span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default WalletChecker;